.uxsignals-embed {
  font-family: InnovationNorway, Helvetica, Arial, sans-serif;
  border-radius: 0.5rem;

  h2 {
    font-size: 1.25rem;
  }

  .uxsignals-cta-container {
    margin-bottom: 0;
  }

  button {
    display: flex;
    padding: 0.625rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;

    border-radius: 0.125rem;
    @apply bg-in-white border border-solid border-in-blue-150 !important;
  }
  /* Nesting does not work here (with UXSignals) so we have to declare it explicitly */
  button:hover {
    color: black !important;
    @apply bg-in-blue-25 !important;
    transition: ease-in-out 0.2s;
  }
}

.uxsignals-container {
  font-family: InnovationNorway, Helvetica, Arial, sans-serif;

  /* Have to ask if the container has an embed child because embed does not cover the entire width... */
  &:has(.uxsignals-embed) {
    @apply bg-in-black-12 !important;
  }
}

div.first-button-blue {
  button:first-of-type {
    @apply bg-in-blue-100 !important;
  }
  button:first-of-type:hover {
    @apply bg-in-blue-button-hover !important;
  }
}
