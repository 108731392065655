@import 'variables';
@import 'breakpoints';

@import '@in/component-library/dist/component-library.css';

* {
  font-feature-settings: 'tnum', 'calt', 'kern';
  font-variant-numeric: tabular-nums;
}

html {
  box-sizing: border-box;
  font-size: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 100%;
  line-height: normal;
}

.app-body {
  margin: 0 auto;
  max-width: 640px;
  min-width: 340px;

  .app-content {
    margin: 0 16px;
  }
}

.card {
  padding: 32px;
  background-color: $bg-gray-light;
  @include breakpoint-sm-max {
    padding: 16px;
  }

  > :first-child {
    margin-top: 0;
  }
}

.noWrap {
  white-space: nowrap;
}

h1.lookAsH2 {
  font-size: 33px;
  line-height: 42px;
}

h2.lookAsH3 {
  font-size: 28px;
  line-height: 34px;
}

.linkButton {
  border: none;
  text-decoration: none;
  background-color: transparent;
  text-align: left;
  font-size: inherit;
  font-family: inherit;
  padding: 0;

  &:hover,
  &:focus {
    cursor: pointer;
    text-decoration: underline;
    outline: none;
  }
}

.text {
  &-right {
    text-align: right;
  }

  &-center {
    text-align: center;
  }

  &-left {
    text-align: left;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  &:first-child {
    margin-top: 0px;
  }
}

.noClearance {
  width: 100%;
  margin-top: 1rem;
}

.blocker {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.05);
  z-index: 10;
  margin: auto;
}

.blocker.blocked {
  display: flex;
  justify-content: center;
  align-items: center;
}
