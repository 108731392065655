.pageLoaderWrapper {
  width:100%;
  display: flex;
  flex-direction: row;
  margin: 5rem 0;
  justify-content: center;
  .loading {
    background-color: white;
  }
}
