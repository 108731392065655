@import '../../../styles/variables.scss';

.page {
    width: 100%;
    margin-left:auto;
    margin-right:auto;
  }

  .page h1 {
    margin-top: 10px;
  }
  