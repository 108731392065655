@import '../styles/variables.scss';
@import '../styles/breakpoints.scss';

footer > div > section > div,
header > section > div {
  background-color: $bg-white;
  overflow-wrap: break-all;
}

.layout-mainContent {
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 100vh;
  background-color: $bg-white;

  @include breakpoint-sm-max {
    display: grid;
  }
}

.layout-content {
  flex: 1 0 auto;
}

.layoutGridMain {
  .app-content {
    grid-column: span 4;

    margin: 0 auto;
    width: 100%;

    @include breakpoint-sm {
      grid-column: span 4;
    }
    @include breakpoint-md {
      grid-column: span 8;
    }
    @include breakpoint-lg {
      grid-column-start: 2;
      grid-column-end: 12;
    }
  }
}

.layout-footer {
  flex-shrink: 0;
}
