@import '../../../styles/variables.scss';
@import '../../../styles/breakpoints.scss';

.detailViewNoFlex {
  font-size: 0.9rem;

  @include breakpoint-sm {
    font-size: 1rem;
  }

  @include breakpoint-sm-max {
    margin-top: 8px;
  }
  margin-right:10%;
}

.detailView {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  @include breakpoint-sm-max {
    margin-top: 8px;
  }
}

.detailView.noTopMargin {
  display: flex;
  justify-content: space-between;
  margin-top: 0px;
}

.detailView.noTopMargin.footer {
  font-size: 0.9rem;
  @include breakpoint-sm {
    font-size: 1rem;
  }
}

.detailView.noTopMargin.header {
  font-weight: $font-weight--bold;
  color: $black;
}

.detailViewValue {
  font-weight: $font-weight--bold;
  color: $black;
  white-space: nowrap;
}

.approvedWarning {
  color: $lima;
}

.footerText {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.footerTextPrefix {
  white-space: nowrap;
}