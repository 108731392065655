@import '../../../styles/variables.scss';
@import '../../../styles/breakpoints.scss';

.localTabs {
  .tabMenu {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    margin: 5px 0px;
    border-bottom: 1px solid $color--grey-70;

    li {
      margin: 0;
      cursor: pointer;

      .labelWrapper {
        white-space: nowrap;
        display: flex;
      }
      .labelSpace {
        padding: 0px 15px 0px 0px;
      }
    }
  }
}
