/**
 * Breakpoints
 */

@mixin breakpoint-xs {
  @content;
}

@mixin breakpoint-sm {
  @media screen and (min-width: $breakpoint-sm) {
    @content;
  }
}

@mixin breakpoint-sm-max {
  @media screen and (max-width: $breakpoint-sm) {
    @content;
  }
}

@mixin breakpoint-md {
  @media screen and (min-width: $breakpoint-md) {
    @content;
  }
}

@mixin breakpoint-md-max {
  @media screen and (max-width: $breakpoint-md) {
    @content;
  }
}

@mixin breakpoint-lg {
  @media screen and (min-width: $breakpoint-lg) {
    @content;
  }
}

@mixin breakpoint-table {
  @media screen and (min-width: $breakpoint-table) {
    @content;
  }
}
