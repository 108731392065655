.customerTaskList {
  & > div {
    margin-top: 1rem;
  }

  .taskList {
    padding-inline-start: 0px;
    list-style-type: none;
    .customerTaskListItem {
      button {
        margin-bottom: 1.2rem;
        border: solid 2px #fff;
        text-decoration: none;
        background-color: transparent;
        text-align: left;
        font-family: inherit;
        padding: 0;
        display: block;
        width: 100%;

        &.disabled {
          pointer-events: none;
        }

        &:hover {cursor: pointer};
        
        &:focus {
          cursor: pointer;
          outline: auto;
          border: solid 2px;
        }
      }

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
