@import '../../styles/variables.scss';
@import '../../styles/breakpoints.scss';

.applicationDetails {
  .heading {
    margin-top: 2rem;
    margin-bottom: 0rem;
    font-weight: 700;
    .number {
      background-color: #eee;
      font-weight: 500;
    }
  }
  .yourCase {
    box-shadow: inset 0.1rem 0rem #ccc;
    padding-left: 1.3rem;
    margin-left: 0.9rem;
    @include breakpoint-md {
        padding-left: 1.3rem;
    }
    @include breakpoint-sm {
        padding-left: 0.8rem;
    }
    @include breakpoint-sm-max {
        padding-left: 0.8rem;
    }
    @include breakpoint-lg {
        padding-left: 1.3rem;
    }
  }
.textBox {
    margin-top: 0.3rem;
    .text {
      margin-top:0rem;
      padding-top:1rem;
    }
  }
}
