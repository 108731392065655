@import '../styles/variables.scss';
@import '../styles/breakpoints.scss';

.navMenuTabs {
    button {
        @include breakpoint-sm {
            font-size: 110%;
          }
    }
  button:focus {
    border-bottom: 1px solid #222;
    font-weight: 700;
  }
  button:hover {
    border-bottom: 1px solid #222;
  }
}
