.footer-icon {
  margin-right: 15px;
}
.footer-icon-empty {
  margin-left: 2.5rem;
}
.footer-iconRow {
  display: flex;
  justify-content: left;
  margin-bottom: 5px;
}
.noBreak {
  white-space: nowrap;
}
.footer-contacts {
  padding-bottom: 20px;
  word-break: break-word;
}
.footer-icon-small {
  margin-right: 20px;
}
.footer-link:hover {
  text-decoration: underline;
}

.disabled-link {
  pointer-events: none;
  display: block;
  border-bottom: none !important;
}
