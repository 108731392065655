@import '../../../styles/variables.scss';
@import '../../../styles/breakpoints.scss';

.accordion__container {
  padding: 0;
  list-style-type: none;
  border-bottom: 1px solid $quill-gray;

  // Accordion in Accordion
  .accordion__container {
    .accordion .accordion__button {
      background-color: $bg-gray-light;
      padding: 8px 32px;

      @include breakpoint-sm-max {
        padding: 8px 16px;
      }

      &.accordion__button--active {
        background-color: $quill-gray;
      }
    }
  }
}

// Different size of headings
.accordion .text {
  font-size: 1.25rem;
  line-height: 1.25rem;
  font-weight: $font-weight--normal;
  @include breakpoint-sm {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
}

.accordion {
  border-top: 1px solid $quill-gray;

  &__button {
    text-align: left;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 10px 8px;
    display: flex;
    flex-direction: row;
    width: 100%;

    &:hover {
      background-color: $quill-gray;
    }

    .text {
      flex: 1;
    }

    .arrow {
      border: solid black;
      border-width: 0 2px 2px 0;
      display: block;
      padding: 4px;

      &__up {
        margin-top: 8px;
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
      }

      &__down {
        margin-top: 4px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }
    }
  }
}

.accordion .accordion {
  border-top: 0px;
}

.accordion__content {
  margin-bottom: 32px;
  display: none;
  &.accordion__content--active {
    display: block;
  }
}

.accordion__content {
  margin-bottom: 0px;
}
