@forward "@in/component-library/src/styles/variables.scss";
@import "@in/component-library/src/styles/variables.scss";

//
// Colors
//

//  DEPRECATED - use Tailwind variables instead. See tailwind.css (@apply) and tailwind.config.js

.vars {
    --quill-gray-rgb: 217, 217, 214;
}

$black:         rgb(0,0,0);
$quill-gray:    rgb(217,217,214);
$tower-gray:    rgb(177,179,179);
$abbey:         rgb(83,86,90);

$swirl:         rgb(215,210,203);
$bronco:        rgb(172,163,154);
$pine-cone:     rgb(110,98,89);

$romantic:      rgb(255,210,187);
$regent-blue:   rgb(164,219,232);
$botticelli:   rgb(200,214,227);
$mandy:         rgb(224,62,82);
$riptide:       rgb(122,225,191);
$portica:       rgb(246,235,97);
$pomegranate:   rgb(237,52,38);
$green:           rgb(67,158,122);
$orange:        #FF5C00;
$orange-light:   #FFD2BB;
$orange-light-50: mix(#FFD2BB, #FFF, 50%);
$orange-text:     mix(#000, #ED3426, 25%);
$lima:          rgb(0,87,184);
$invalid-gray:  #B1B3B3;
$background-message-yellow: mix(#FFF, #F6EB61, 50%);

// Same color as the fill in download and pdf icon
$download-blue: #0057b8;

//
// BG Colors
//

$bg-white:          #FFF;
$bg-gray-light:     rgba($quill-gray, 0.3);
$bg-gray:           $quill-gray;

$bg-beige:          $swirl;
$bg-blue-light:     rgba($regent-blue, 0.3);
$bg-blue-lighter:     rgba($botticelli, 0.3);

//
// Breakpoint customization
//

$breakpoint-sm: 35em;

// Breakpoint used for differentiating mobile view and desktop view in the tables.
// Has now been converted to a variable in the Tailwind config. Please use Tailwind instead
$breakpoint-table: 850px;

// new colors from figma
$gray-190: #201F1E;
