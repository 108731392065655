/*  Tailwind */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Tailwind normalizes the application CSS, which is a good thing. Every browser and/or device can have a different 
user agent stylesheet. Declaring our styles explicit ensure we have the same look on all browser and devices.  */

/* IN component library does not normalize CSS on its own components, which may cause us to write missing CSS explicitly */

@layer base {
  a {
    /*  We can apply the color from our variables declared in Tailwind configuration, doing so we only have one source of truth */
    @apply text-in-hyperlink-blue;
  }

  /* Dialog components have a padding in the user agent stylesheet, which IN component libraries assumes is there.
  Tailwind normalizes it, therefore we have to put it back in */
  dialog {
    padding: 1em;
  }

  ol,
  ul,
  menu {
    list-style: disc;
    padding-inline-start: 40px;
    margin-block-start: 1em;
    margin-block-end: 1em;
  }

  p {
    margin: 1em 0
  }

  h1 {
    margin-top: 0.67em;
    margin-bottom: 0.67em;
  }
  
  h2 {
    margin-top: 0.83em;
    margin-bottom: 0.83em;
  }
  
  h3 {
    margin-top: 1em;
    margin-bottom: 1em;
  }
  
  h4 {
    margin-top: 1.33em;
    margin-bottom: 1.33em;
  }
  
  h5 {
    margin-top: 1.67em;
    margin-bottom: 1.67em;
  }
  
  h6 {
    margin-top: 2.33em;
    margin-bottom: 2.33em;
  }
}
