// This fixes a couple of bugs in CL version 11.6.3. HD is notified.
nav.breadcrumbs {
  &.fixRootPage {
    & :nth-child(-n + 2) {
      display: none;
    }
    & :nth-child(3) {
      vertical-align: middle;
    }
  }
  &:not(.fixRootPage) {
    svg {
      vertical-align: middle;
      transform: translateY(-4px);
    }
  }
  & :last-child {
    vertical-align: bottom;
  }
}
