@import '../../styles/variables.scss';
@import '../../styles/breakpoints.scss';
.documentList {
  line-height: 3.75rem;
  margin-top: 1rem;
  padding-inline-start: 0px;
  list-style-type: none;

  @include breakpoint-sm-max {
    line-height: 3rem;
  }
  .documents.downloadLink {
    background-color: #fff;
    color: $in--hyperlink-blue;
    cursor: pointer !important;
    display: flex;
    flex-direction: row;

    &.downloading {
      cursor: default !important;
    }
    &.read {
      color: $in--hyperlink-purple;
    }

    & .fa-check {
      font-size: 125%;
      margin-left: 0.19rem;
    }
    & .fa-exclamation-circle {
      font-size: 125%;
      margin: auto auto;
    }
    & .left {
      padding-left: 1rem;

      @include breakpoint-sm-max {
        padding-left: 0.5rem;
      }
    }
    & .right {
      font-size: 140%;
      line-height: 210%;
      padding-right: 1rem;

      @include breakpoint-sm-max {
        padding-right: 0.5rem;
      }
    }
    & .downloadSpinner {
      margin-right: -6px;
      margin-top: -3px;
      padding-right: 0px;
    }
  }
  .downloadTextWrapper {
    margin: 1rem auto;
    padding: 0rem 0rem;
    width: 100%;
  }
  .downloadText {
    line-height: 100%;
    margin: none;
    padding: 0rem 1rem;
    text-decoration: underline;

    @include breakpoint-sm-max {
      padding: 0rem 0.5rem;
    }
  }
}
