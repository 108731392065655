@import "../../../styles/breakpoints.scss";
@import "../../../styles/variables.scss";

.GoBack {
    display: none;
    @include breakpoint-sm-max {
        display: flex;
    }
    margin-left: -3px;

    &.always {
        display: flex;
    }

    a {
        border: 0;
        transition: none;

        &:hover {
            border: 0;
        }
    }

    .arrow {
        border: solid black;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        margin-bottom: 2px;

        &.left {
            transform: rotate(135deg);
            -webkit-transform: rotate(135deg);  
        }
    }
}