@import '../../../styles/variables.scss';
@import '../../../styles/breakpoints.scss';

.customerTaskCardBase {
  // CSS overrides from the card component in IN Component library
  padding: 0;
  border-radius: 0;
  padding: 0 0 0 4px;
  overflow: unset;

  &.blue {
    box-shadow: 0 2px 8px #00000040, inset 4px 0 0 #3baccc;
  }

  &.green {
    box-shadow: 0 2px 8px #00000040, inset 4px 0 0 #239955;
  }

  &.gray {
    box-shadow: 0 2px 8px #00000040, inset 4px 0 0 #b1b3b3;
  }

  &.red {
    box-shadow: 0 2px 8px #00000040, inset 4px 0 0 #e10000;
  }

  &.black {
    box-shadow: 0 2px 8px #00000040, inset 4px 0 0 #808080;
  }

  .cardGrid {
    display: grid;
    grid-template-areas: 'header header' 'content arrow';

    div.header {
      grid-area: header;
      background-color: #d6f6ff;
      font-size: 1rem;
      width: 100%;
      display: flex;
      padding: 1rem 1.5rem;

      font-family: InnovationNorway, Helvetica, Arial, sans-serif;
      font-weight: 400;
      line-height: 150%;

      &.blue {
        background-color: #d6f6ff;
      }

      &.green {
        background-color: #cfffe3;
      }

      &.red {
        background-color: #ffeef1;
      }

      &.yellow {
        background-color: #fffbb8;
        // Generic cards have less padding
        padding-top: 4px;
        padding-bottom: 4px;
      }

      &.gray {
        background-color: #f2f2f2;
        // Gray cards have less padding
        padding-top: 4px;
        padding-bottom: 4px;
      }
    }

    div.content {
      grid-area: content;
      padding: 1.25rem;
      overflow: hidden;

      .type {
        font-size: 0.875rem;
        font-weight: $font-weight--bold;
        color: $color--grey-700;
        @include breakpoint-sm {
          font-size: 1.1rem;
        }
      }

      .title {
        margin: 4px 0;
        font-weight: $font-weight--bold;
      }

      .message {
        font-size: 1.1rem;
        font-weight: 600;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .date {
        font-size: 0.875rem;
        color: $color--grey-700;

        @include breakpoint-sm {
          font-size: 1.1rem;
        }
      }
    }

    div.arrow {
      display: grid;
      grid-area: arrow;

      align-items: center;
      justify-content: end;
      padding-right: 1.25rem;
    }
  }

  .icon {
    border-radius: 50%;
    color: #fff;
    font-size: 1rem;
    height: 1.5rem;
    left: -0.75rem;
    position: absolute;
    text-align: center;
    top: 0.9375rem;
    width: 1.5rem;

    &.success {
      background: #239955;
    }
    &.info {
      background: #3baccc;
    }

    &.error {
      background: #e10000;
    }
  }
}
